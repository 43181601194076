// import logo from './BackGround.png';
import '../App.css';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import Services from '../Components/ByvrServices';
import LatestNews from '../Components/ByvrLatestNews';
import PricingTables from '../Components/ByvrPricing';
import NewsLetter from '../Components/ByvrNewsLetter';
import Footer from '../Components/ByvrFooter';
import GoogleMaps from '../Components/ByvrAddressSearchField';

function ByvrHome({userData}) {
    return (
        <>
            <Box className="banner" id="banner">
                <Box id="bannerCarousole" className="carousel slide" data-ride="carousel">
                    <Box className="carousel-inner">
                        <Box className="carousel-item banner-max-height active item-bg">
                            <img className="d-block w-100 h-100" src="img/banner/img-3.jpg" alt="banner" />
                            <Box className="carousel-caption banner-slider-inner d-flex text-center"></Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="bi-4">
                    <Box className="container">
                        <Box className="text-center">
                            <h1 className="t-choto">Buy vs Rent - which is better for you?</h1>
                            <p>AI driven comparative insights</p>
                            <Box className="inline-search-area ml-auto mr-auto">
                                <GoogleMaps className="searchField" userData={userData}  />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Services />
            <LatestNews />
            <PricingTables />
            <NewsLetter />
            <Footer />
        </>
    );
}

export default ByvrHome;
