// ThemeContext.js
import React, { createContext, useState, useMemo, useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { compactTheme, defaultTheme } from './byvrDefaultTheme';

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const CustomThemeProvider = ({ children }) => {
  const [isCompact, setIsCompact] = useState(true);

  const theme = useMemo(() => (isCompact ? compactTheme : defaultTheme), [isCompact]);

  const toggleTheme = () => {
    setIsCompact(prevIsCompact => !prevIsCompact);
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, isCompact }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
