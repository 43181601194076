/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable implicit-arrow-linebreak */
import resolveConfig from 'tailwindcss/resolveConfig';

export const tailwindConfig = () =>
    // Tailwind config
    resolveConfig('../tailwind.config.js');

export const hexToRGB = (h) => {
    let r = 0;
    let g = 0;
    let b = 0;
    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }
    return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    notation: 'compact',
}).format(value);

export const getHovervalue = (context) => {
    const year = new Date(context.label).getYear() + 1900;
    return `${year} ${formatValue(context.parsed.y)}`;
    // return "ABC"
};
