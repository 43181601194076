/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';
import { SvgIcon, useTheme, Grid, Box, Container, Typography, IconButton ,useMediaQuery} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import ByvrDashboardView from '../Components/dashboard/ByvrDashboardView';
import ByvrChatBot from '../Components/AI/ByvrChatBot';
import ByvrAIHouseSummary from '../Components/AI/ByvrAIHouseSummary';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './ByvrPagesLayout.css';
import { getHouseDetails } from '../Services/dataService';
import { useDispatch } from 'react-redux';
import { housedetails } from '../features/house/houseSlice';
import { formatValue } from '../utils/Utils';


function HomeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

function ByvrDashboard() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchParams] = useSearchParams();
    const [address, setAddress] = useState({});
    const [houseDetails, setHouseDetails] = useState({});
    const [toggleinfo, setToggleInfo] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        const street_address = searchParams.get('address');
        const google_place_id = searchParams.get('google_place_id');
        console.log(google_place_id, "google_place_id");
        setAddress({ street_address, google_place_id });
        getHouseDetails(street_address, google_place_id).then((houseDetail) => {
            setHouseDetails(houseDetail)
            if (houseDetail && Object.keys(houseDetail).length > 0) {
                const fullHouseData = {
                    house_price: houseDetail.house_price ,
                    down_payment_percent: houseDetail.down_payment_percent|| 20,
                    interest_rate: houseDetail.interest_rate ,
                    intial_rent: houseDetail.intial_rent ,
                    bathrooms: houseDetail.bathrooms || 3 ,
                    bedrooms: houseDetail.bedrooms || 3,
                    hold_for_years: houseDetail.hold_for_years||20,
                    HOA: houseDetail.HOA ,
                    insurance_cost:houseDetail.insurance_cost || '',
                    address:street_address,
                    google_place_id:google_place_id,
                    property_tax_rate: houseDetail.property_tax_rate || 1,
                    buying_closing_costs: houseDetail.buying_closing_costs || 2.5,
                    condo_fees: houseDetail.condo_fees || 0,
                    additional_monthly_costs: houseDetail.additional_monthly_costs || '',
                    house_price_appreciation: houseDetail.house_price_appreciation || 4,
                    intial_rent: houseDetail.intial_rent || '',
                    monthly_renters_insurance: houseDetail.monthly_renters_insurance || '',
                    rent_deposit: houseDetail.rent_deposit || '',
                    brokers_fees: houseDetail.brokers_fees || '',
                    rent_appreciation: houseDetail.rent_appreciation || '',
                    buyerStatus: houseDetail.buyerStatus || 1,
                };
                dispatch(housedetails(fullHouseData));
                
            }
        });

    }, [searchParams]);

    return (
        <>
            <Box
                width="100%"
                height="3rem"
                display="flex"
                alignItems="center"
                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                bgcolor="#fff"
            >
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Link to='/'>
                                <ArrowBackIcon />
                            </Link>
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" gap={isMobile ? 1 : 2}>
                               {!isMobile && <img src={houseDetails["imgSrc"] ?   houseDetails["imgSrc"] : "img/thm.png"} alt="logo" style={{ width: '3rem', height: '3rem', marginRight: '16px' }} />}
                                <Typography variant={isMobile ? 'subtitle1' : 'h6'} textAlign="center">
                                    {address.street_address}
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    Price : {formatValue(houseDetails.house_price)}
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    Rental : {formatValue(houseDetails.intial_rent)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box display="flex" height="75vh" flexDirection={{ xs: 'column', sm: 'row' }}>
                <Box flex={1}>
                    <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                            <div className=""></div>
                        </div>
                        <div className="chartjs-size-monitor-shrink">
                            <div className=""></div>
                        </div>
                    </div>
                    {address && (
                        <Grid item xs={6} sx={{ maxHeight: "75vh", overflow: "auto" }}>
                            <ByvrDashboardView selectedAddress={address} ></ByvrDashboardView>
                        </Grid>
                    )}
                </Box>
                <Box flex={1}>
                    {address.street_address && (<Grid item xs={6} sx={{ maxHeight: "75vh", overflow: "auto" }}>
                        <ByvrAIHouseSummary street_address={address.street_address} google_place_id={address.google_place_id} selectedAddress={address.street_address}></ByvrAIHouseSummary>
                    </Grid>
                    )}
                </Box>
            </Box>
            <>
                {address.street_address &&
                    (<ByvrChatBot toggleinfo={toggleinfo} setToggleInfo={setToggleInfo} street_address={address.street_address} google_place_id={address.google_place_id} selectedAddress={address.street_address} mobileView={false} />
                    )}
            </>
        </>
    );
}

export default ByvrDashboard;
