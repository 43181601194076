import { Box } from '@mui/material';
import React from 'react';
import {formatValue} from '../utils/Utils'

function ByvrHouseListView({ addresses, selectedAddress, inputLocation, onRowClick }) {
    return (
        <div className="msb" id="msb">
            <nav className="navbar navbar-default" role="navigation" style={{ display: 'block' }}>
                <div className="side-menu-container">
                    <ul className="nav navbar-nav">
                        <li className="panel panel-default">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan="4">
                                                Review {addresses.length} plus properties.
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addresses.map((address, index) => (
                                            <Box
                                                key={index}
                                                className="toggle"
                                                onClick={() => onRowClick(address)}
                                                role="button"
                                                tabIndex={0}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <tr >
                                                    <td>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={`customCheck${index}`}
                                                                checked={selectedAddress.address === address.address}
                                                                readOnly
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`customCheck${index}`}
                                                            ></label>
                                                        </div>
                                                    </td>
                                                    <td className="hous">
                                                        <img src={address.imgSrc} alt="logo" />
                                                    </td>
                                                    <td className='w-100'>
                                                        <br />
                                                        {address.address}
                                                        <br />
                                                        <Box>
                                                            <div>
                                                                <span style={{ fontWeight: 'bold' }}>Bed : {address.bedrooms}</span>&nbsp;&nbsp;
                                                                <span style={{ fontWeight: 'bold' }}>Bath : {address.bathrooms}</span>&nbsp;&nbsp;
                                                            </div>
                                                            <div>
                                                                <span style={{ fontWeight: 'bold' }}>Est : {formatValue(address.zestimate)}</span>&nbsp;&nbsp;
                                                                <span style={{ fontWeight: 'bold' }}>Rent : {formatValue(address.rentZestimate)}</span>
                                                            </div>
                                                        </Box>
                                                        <br />
                                                    </td>
                                                </tr>
                                            </Box>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div id="dropdown-lvl1" className="panel-collapse collapse">
                                <div className="panel-body">
                                    <img src="img/grf.jpg" alt="logo" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default ByvrHouseListView;
