/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import log from 'loglevel';
import React, { useState, useEffect } from 'react';
import {
    Toolbar, Grid, Typography, IconButton, Tabs, Tab, Box,
} from '@mui/material';
import './ByvrPagesLayout.css';
import { useSearchParams } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import TableChartIcon from '@mui/icons-material/TableChart';
import ByvrHouseListView from '../Components/ByvrHouseListView';
import ByvrHouseLMapView from '../Components/ByvrHouseMapView';
import { getHouseList, google_house_details } from '../Services/dataService';
import ByvrChatBot from '../Components/AI/ByvrChatBot';
import ByvrMobileNav from '../Components/ByvrMobileNav';
import ByvrAIHouseSummary from '../Components/AI/ByvrAIHouseSummary';
import ByvrAILocationSummary from '../Components/AI/ByvrAILocationSummary';
import ByvrDashboardView from '../Components/dashboard/ByvrDashboardView';

const toolbar = {
    display: 'flex',
    maxHeight: '2rem',
    minHeight: '1.5rem',
    backgroundColor: 'lightGrey',
    justifyContent: 'space-between',
};

function ByvrHouseListHeader({ inputLocation, onViewChange, view }) {
    return (
        <Toolbar style={toolbar}>
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                {inputLocation}
            </Typography>
            <IconButton onClick={onViewChange}>
                {view === 'table' ? (<MapIcon />) : (<TableChartIcon />)}
            </IconButton>
        </Toolbar>
    );
}

function ByvrHouseDashboardHeader({ selectedAddress, activeTab, handleTabChange }) {
    return (
        <Box>
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                {selectedAddress.address}
            </Typography>
            <Tabs className="dashboard-header" value={activeTab} onChange={handleTabChange} sm={{ minHeight: '1rem' }}>
                <Tab label="Analysis" value="dashboards" />
                <Tab label="AI Generated Summary" value="ai-summary" />
                {/* <Tab label="House Detail" value="tabs-3" /> */}
            </Tabs>
        </Box>
    );
}

const sample_addresses = [];

function ByvrDashboardBulk() {
    const [searchParams] = useSearchParams();
    const [addresses, setAddresses] = useState(sample_addresses);
    const [toggleinfo, setToggleInfo] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [view, setView] = useState('table');
    const [activeTab, setActiveTab] = useState('dashboards');

    const handleViewChange = () => {
        setView(view === 'table' ? 'map' : 'table');
    };

    const handleRowClick = (address) => {
        log.debug(address);
        setView('table');
        google_house_details(address.address)
            .then((google_details) => {
                address.street_address = address.address;
                address.google_place_id = google_details.Place_Id;
                setSelectedAddress(address);
            });
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        const street_address = searchParams.get('address');
        const google_place_id = searchParams.get('google_place_id');
        console.log(google_place_id, "street_address");
        getHouseList(street_address, google_place_id)
            .then((houseList) => setAddresses(houseList))
            .catch(() => setAddresses(sample_addresses));
    }, [searchParams]);

    return (
        <div style={{ width: '100%', height: '100%', float: 'left' }}>
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <main style={{ width: '100%', height: '100%', float: 'left' }}>
                    <Box className="dashboard-container" style={{ width: '100%', height: 'calc(98vh - 4.5rem)', float: 'left' }}>
                        <Grid container spacing={2} >
                        <Grid item xs={4} className='house-list-mobile' style={{ maxWidth: "450px", minWidth: "300px", width: '100%',  height: '95vh', float: 'left' }}>
                                <ByvrHouseListHeader
                                    inputLocation={searchParams.get('address')}
                                    onViewChange={handleViewChange}
                                    view={view}
                                />
                                <ByvrHouseListView
                                    addresses={addresses}
                                    inputLocation={searchParams.get('address')}
                                    onRowClick={handleRowClick}
                                    selectedAddress={selectedAddress}
                                    onViewChange={handleViewChange}
                                />
                            </Grid>
                            <Grid item xs={8} style={{ width: '100%', height: '100%' }}>
                                {view === 'map' ? (
                                    <ByvrHouseLMapView
                                        addresses={addresses}
                                        onAddressClick={handleRowClick}
                                        selectedAddress={selectedAddress}
                                        onViewChange={handleViewChange}
                                    />
                                ) : (
                                    <div className="dashboard-item" style={{ width: '100%' }}>
                                        <div style={{ width: '100%', height: '95vh' }}>
                                            {Object.keys(selectedAddress).length === 0 ? (
                                                <ByvrAILocationSummary street_address={searchParams.get('address')} google_place_id={searchParams.get('google_place_id')} selectedAddress={selectedAddress.address} ></ByvrAILocationSummary>
                                            ) : (
                                                <Box className="tab-content">
                                                    <ByvrHouseDashboardHeader
                                                        selectedAddress={selectedAddress}
                                                        activeTab={activeTab}
                                                        handleTabChange={handleTabChange}
                                                    />
                                                    {activeTab === 'dashboards' && Object.keys(selectedAddress).length > 0  && (
                                                        <div style={{overflow:"auto" , height: 'calc(100vh - 15rem)' }}>
                                                        <ByvrDashboardView  selectedAddress={selectedAddress}></ByvrDashboardView>
                                                        </div>

                                                    )}
                                                    {activeTab === 'ai-summary' && (
                                                        <div style={{ width: '100%', height: 'calc(100vh - 15rem)' }}>
                                                            <ByvrAIHouseSummary street_address={searchParams.get('address')} google_place_id={searchParams.get('google_place_id')} selectedAddress={selectedAddress.address} ></ByvrAIHouseSummary>
                                                        </div>
                                                    )}
                                                </Box>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                    <>
                        <ByvrChatBot toggleinfo={toggleinfo} setToggleInfo={setToggleInfo} street_address={searchParams.get('address')} google_place_id={searchParams.get('google_place_id')} selectedAddress={selectedAddress.address} mobileView={false} />
                    </>
                    {/* <>
                        { Object.keys(selectedAddress).length > 0  && <ByvrMobileNav addresses={addresses}
                            inputLocation={searchParams.get('address')}
                            onRowClick={handleRowClick}
                            selectedAddress={selectedAddress}
                            onAddressClick={handleRowClick}
                            onViewChange={handleViewChange} />}
                    </> */}
                </main>
            </div>
        </div>
    );
}

export default ByvrDashboardBulk;
