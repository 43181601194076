
import aiService from '../../Services/aiService';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IconButton, TextField, Box, Paper , Typography } from '@mui/material';

const ByvrAIResponse = ({response}) => {

    return (<ReactMarkdown
        children={response}
        remarkPlugins={[remarkGfm]}
        components={{
            p: ({ children }) => <Typography variant="body2" component="p">{children}</Typography>,
            code: ({ inline, className, children, ...props }) => (
                <Typography
                    component="code"
                    sx={{
                        backgroundColor: '#f5f5f5',
                        padding: '2px 4px',
                        borderRadius: '4px',
                        fontSize: '0.7em',
                        display: 'inline-block',
                    }}
                    {...props}
                >
                    {children}
                </Typography>
            ),
        }}
    />)
}

export default ByvrAIResponse;