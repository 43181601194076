import React, {  useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

export default function Services() {
    // const videoRef = useRef(null);
    // const [isMuted, setIsMuted] = useState(true);

    // const handleMuteToggle = () => {
    //     if (videoRef.current) {
    //         videoRef.current.muted = !videoRef.current.muted;
    //         setIsMuted(videoRef.current.muted);
    //     }
    // };

    return (
        <Box id="mission-section" className="services content-area relative">
            <div className="video">
                <video  src={require('../images/byvr_video.mp4')} autoPlay muted controls loop playsInline title="AI Video" style={{ borderRadius: "10px", width: '100%', height: '100%' }} />
                {/* <button onClick={handleMuteToggle} style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer',color:'white' }}>
                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button> */}
            </div>
            <Container className='mb-6'>
                <Box className="main-title text-center">
                    <Typography variant="h1" component="h2">byvr</Typography>
                    <p>How it works?</p>
                </Box>
                <Box className="row">
                    <Box className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <Box className="service-info">
                            <Box className="icon">
                                <i className="flaticon-find-1"></i>
                            </Box>
                            <Typography variant="h3" component="h3">Return On Investments</Typography>
                            <p>Byvr uses properietary alogrithm to compare return on investments for buying or renting a proaspective property. Insights are generated based on configurable models</p>
                        </Box>
                    </Box>
                    <Box className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <Box className="service-info">
                            <Box className="icon">
                                <i className="flaticon-mail"></i>
                            </Box>
                            <Typography variant="h3" component="h3">AI Driven Insights</Typography>
                            <p>Byvr uses latest generative AI models to enchance and suplement ROI trends, providing comprehensive market area information
                            </p>
                        </Box>
                    </Box>
                    <Box className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <Box className="service-info">
                            <Box className="icon">
                                <i className="flaticon-user"></i>
                            </Box>
                            <Typography variant="h3" component="h3">Fastest Updates</Typography>
                            <p>Byvr updates its existing database of locations frequently with upcoming projects like
                                highways,
                                airport,malls etc which affect the proce of a property.</p>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
