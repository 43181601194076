import React from 'react';
import { TextField, Slider } from '@mui/material';

const SliderInputField = ({
    label,
    value,
    onChange,
    min = 0,
    max = 100,
    step = 0.01,
    inputType = 'number',
    sliderColor = '#f0151f',
}) => {
    const handleSliderChange = (event, newValue) => {
        onChange(newValue);
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (newValue === '') {
            onChange(null);
        } else {
            onChange(parseFloat(newValue));
        }
    };

    return (
        <>
            <TextField
                fullWidth
                label={label}
                variant="outlined"
                size="small"
                value={value || ''}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    readOnly: false,
                    type: inputType,
                    step: step,
                }}
                onChange={handleInputChange}
            />
            <Slider
                value={value || 0}
                onChange={handleSliderChange}
                min={min}
                max={max}
                step={step}
                aria-labelledby={`${label}-slider`}
                sx={{
                    color: sliderColor,
                    '& .MuiSlider-thumb': {
                        backgroundColor: '#fff',
                        border: `1px solid ${sliderColor}`,
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: sliderColor,
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#00ea92a3',
                    },
                }}
            />
        </>
    );
};

export default SliderInputField;
