import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto'; // Ensure you use the correct version of Chart.js
import './ByvrChatBot.css';
import { IconButton, TextField, Box, Paper, Typography } from '@mui/material';
import ByvrAIResponse from './ByvrAIResponseView';
import aiService from '../../Services/aiService';
import LoadingComponent from "./ByvrLoadingAIResponse";

const ByvrAILocationSummary = ({ street_address, selectedAddress, google_place_id }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [loadingAIResp, setLoadingAIResp] = useState(true);
    const [summary, setSummary] = useState("");

    useEffect(() => {

        aiService.get_location_summary(street_address, google_place_id)
            .then(resp => {
                setLoadingAIResp(false)
                setSummary(resp)
            })

        const ctx = chartRef.current.getContext('2d');

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const xValues = ["Cleveland", "Cincinnati", "Columbus", "Akron", "Dayton"];
        const yValues = [55, 49, 44, 24, 15];
        const barColors = ["red", "green", "blue", "orange", "brown"];

        chartInstanceRef.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: xValues,
                datasets: [{
                    backgroundColor: barColors,
                    data: yValues
                }]
            },
            options: {
                plugins: {
                    legend: { display: false },
                    title: {
                        display: true,
                        text: "Price appreciation around Ohio"
                    }
                }
            }
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, []);

    return (
        <div style={{ width: '100%', height: '75%', overflow: 'auto' }}>
            <div style={{ fontSize: '14px', textAlign: 'left' }}>
                <Typography variant="h6" gutterBottom> {street_address} : Summary ( AI generated ) </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Note : Please select a specific house for house specific analytics
                </Typography>
                <div id="location_summary">
                    {loadingAIResp ? (<LoadingComponent />
                    ) : (<ByvrAIResponse response={summary}></ByvrAIResponse>)}
                </div>
                <table hidden id="customers" style={{ textAlign: 'center', width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Area</th>
                            <th>Properties Available</th>
                            <th>Schools</th>
                            <th>Hospitals</th>
                            <th>Appreciation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Area 1</td>
                            <td>26</td>
                            <td>11</td>
                            <td>7</td>
                            <td>19%</td>
                        </tr>
                        <tr>
                            <td>Area 2</td>
                            <td>3</td>
                            <td>18</td>
                            <td>11</td>
                            <td>23%</td>
                        </tr>
                        <tr>
                            <td>Area 3</td>
                            <td>45</td>
                            <td>3</td>
                            <td>1</td>
                            <td>7%</td>
                        </tr>
                        <tr>
                            <td>Area 4</td>
                            <td>12</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9%</td>
                        </tr>
                    </tbody>
                </table>
                <div hidden className="chart">
                    <canvas ref={chartRef} />
                </div>
            </div>
        </div>
    );
};

export default ByvrAILocationSummary;
