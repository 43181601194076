import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import './ByvrNewsLetter.css'
export default function NewsLetter() {
    return (
        <section className="bg-img text-center">
            <Container>
                <Typography variant="h4" component="h4">
                    <strong>Sign up for our newsletter</strong>
                </Typography>
                <Typography variant="h6" component="h6" className="font-alt">Plug in to regular updates</Typography>
                <br /><br />
                <form className="form-subscribe" action="#">
                    <Box className="input-group">
                        <input type="text" className="form-control input-lg" placeholder="Your email address" />
                        <span className="input-group-btn">
                            <button className="btn btn-success btn-lg" type="submit">Subscribe</button>
                        </span>
                    </Box>
                </form>
            </Container>
        </section>
    )
}
