import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { housedetails } from '../../features/house/houseSlice'; 

function RentingSetting() {
    const dispatch = useDispatch();
    const houseDetail = useSelector((state) => state.house.houseDetail); 

    const [formData, setFormData] = useState({
        intial_rent: '',
        monthly_renters_insurance: '',
        rent_deposit: '',
        brokers_fees: '',
        rent_appreciation: '',
    });

    useEffect(() => {
        if (houseDetail) {
            setFormData({
                intial_rent: houseDetail.intial_rent || '',
                monthly_renters_insurance: houseDetail.monthly_renters_insurance || '',
                rent_deposit: houseDetail.rent_deposit || '',
                brokers_fees: houseDetail.brokers_fees || '',
                rent_appreciation: houseDetail.rent_appreciation || '',
            });
        }
    }, [houseDetail]);

    const handleFieldChange = (field, value) => {
        const parsedValue =
            field === 'rent_appreciation'
                ? value === '' ? 0 : Math.max(0, Math.min(100, parseFloat(value))) 
                : value === '' ? 0 : parseFloat(value); 

        setFormData((prev) => ({ ...prev, [field]: value }));
        const updatedHouseData = { ...houseDetail, [field]: parsedValue };
        dispatch(housedetails(updatedHouseData));
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Monthly Rent"
                        variant="outlined"
                        size="small"
                        value={formData.intial_rent}
                        onChange={(e) => handleFieldChange('intial_rent', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01' }} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Renter's Insurance"
                        variant="outlined"
                        size="small"
                        value={formData.monthly_renters_insurance}
                        onChange={(e) => handleFieldChange('monthly_renters_insurance', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01' }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Rent Deposit"
                        variant="outlined"
                        size="small"
                        value={formData.rent_deposit}
                        onChange={(e) => handleFieldChange('rent_deposit', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01' }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Broker's Fees"
                        variant="outlined"
                        size="small"
                        value={formData.brokers_fees}
                        onChange={(e) => handleFieldChange('brokers_fees', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01' }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Rent Appreciation(%)"
                        variant="outlined"
                        size="small"
                        value={formData.rent_appreciation ? formData.rent_appreciation : 3}
                        onChange={(e) => handleFieldChange('rent_appreciation', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01', min: 0, max: 100 }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default RentingSetting;
