export const getAnalyticsInfo = function (address, houseDetail) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const details = Object.fromEntries(
        Object.entries(houseDetail).filter(([key, value]) => value !== null && value !== '' && value !== undefined  && value !== 0)
    );

    const raw = JSON.stringify({
        address: address,
        google_place_id: houseDetail.google_place_id || '',
        price_history: [],
        details: details
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw
    };

    return fetch("/v1/api/analytics/house/" + address, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.error(error));
};

export default {
    getAnalyticsInfo
};
