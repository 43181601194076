import React from 'react';
import {
    Card, CardContent, Typography, Button, Box,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import authServices from '../Services/authService';

function ByvrLoginCard() {
    const handleGoogleLogin = () => {
        authServices.authenticateWithGoogle();
    };

    const handleFacebookLogin = () => {
        authServices.authenticateWithFacebook();
    };

    const handleAppleLogin = () => {
        authServices.authenticateWithApple();
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Card sx={{ minWidth: 300, padding: 3 }}>
                <CardContent>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                        <img src={`${process.env.PUBLIC_URL}/Byvr.jpeg`} alt="Byvr Icon" width="60" height="60" />
                    </Box>
                    <Typography variant="h5" component="div" align="center" gutterBottom>
                        Login
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Button
                            variant="contained"
                            startIcon={<GoogleIcon />}
                            onClick={handleGoogleLogin}
                            sx={{ backgroundColor: '#db4437', color: '#fff', '&:hover': { backgroundColor: '#c33d32' } }}
                            fullWidth
                            aria-label="Login with Google"
                        >
                            Login with Google
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<FacebookIcon />}
                            onClick={handleFacebookLogin}
                            sx={{ backgroundColor: '#3b5998', color: '#fff', '&:hover': { backgroundColor: '#365492' } }}
                            fullWidth
                            aria-label="Login with Facebook"
                        >
                            Login with Facebook
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AppleIcon />}
                            onClick={handleAppleLogin}
                            sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' } }}
                            fullWidth
                            aria-label="Login with Apple"
                        >
                            Login with Apple
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default ByvrLoginCard;
