import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
export default function Pricing() {
    return (
        <Box id="products-section" className="pricing-tables content-area">
            <Container>
                <Box className="main-title text-center">
                    <h1>Products</h1>
                    <p>Below options for enchance value added services</p>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xl={4} lg={4} md={4} sm={12} className='w-100'>
                        <Box className="pricing">
                            <Box className="price-header">
                                <Box className="title">Starter</Box>
                                <Box className="price">FREE</Box>
                            </Box>
                            <Box className="content">
                                <ul>
                                    <li>50 Specific Property Searches</li>
                                    <li>AI Insights</li>
                                </ul>
                                <Box className="sbtn button"><Link to="." className="btn btn-outline pricing-btn">Get Started</Link></Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} className='w-100'>
                        <Box className="pricing featured">
                            <Box className="listing-badges">
                                <span className="featured">Most Popular</span>
                            </Box>
                            <Box className="price-header price-header-2">
                                <Box className="title">Enhanced</Box>
                                <Box className="price"> <strike>$2.99</strike>
                                <span style={{
                                        fontSize: '16px', width: '100%',
                                        float: 'left'
                                    }}> 14 Days Trial </span></Box>
                            </Box>
                            <Box className="content">
                                <ul>
                                    <li>100 Area searches</li>
                                    <li>500 Property searches</li>
                                    <li>AI inshigts</li>
                                    <li>Configure your own model</li>
                                    <li>Save your rresults</li>
                                </ul>
                                <Box className="sbtn button"><Link to="." className="btn btn-outline pricing-btn button-theme">Get
                                    Started</Link></Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} className='w-100'>
                        <Box className="pricing">
                            <Box className="price-header">
                                <Box className="title">Comprehensive</Box>
                                <Box className="price">$9.99/week</Box>
                            </Box>
                            <Box className="content">
                                <ul>
                                    <li>Unlimited searches</li>
                                    <li>AI inshigts</li>
                                    <li>Configure your own model</li>
                                    <li>Save your rresults</li>
                                    <li>API preview</li>
                                </ul>
                                <Box className="sbtn button"><Link to="." className="btn btn-outline pricing-btn">Get Started</Link></Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
