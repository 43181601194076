// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import houseReducer from './features/house/houseSlice';
import userReducer from './features/user/userSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        house: houseReducer,
        user: userReducer,
    },
});

export default store;
