/* eslint-disable no-param-reassign */
import log from 'loglevel';
import store from '../store.js'; 

const state = store.getState();
const userData = state.user; 

const sample_addresses = [
    {
        address: '1146 Charmwood Ct, Sunnyvale, CA 94089',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 1,
        google_place_id: "ChIJjQmTaV0E9YgRC2MLmS_e_mY",
        detailUrl: '/homedetails/1146-Charmwood-Ct-Sunnyvale-CA-94089/19491202_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/d400118941e818736574a7ee42d6a064-p_e.jpg',
        latitude: 37.399227,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1108,
        longitude: -121.99352,
        lotAreaUnit: 'sqft',
        lotAreaValue: 5663,
        price: 1450000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 3966,
        variableData: {
            text: 'Open: Sun. 1:30-4:30pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 1551100,
        zpid: '19491202',
    },
    {
        address: '732 San Ramon Ave, Sunnyvale, CA 94085',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 2,
        google_place_id: "ChIJjQmTaV0E9YgRC2MLmS_e_mY",
        detailUrl: '/homedetails/732-San-Ramon-Ave-Sunnyvale-CA-94085/19544608_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/660cddba7356e21bcd91db35142179c7-p_e.jpg',
        latitude: 37.39071,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1064,
        longitude: -122.00506,
        lotAreaUnit: 'sqft',
        lotAreaValue: 4792,
        price: 1388000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 3825,
        variableData: {
            text: 'Open: Sun. 10am-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 1609500,
        zpid: '19544608',
    },
    {
        address: '1110 Pecos Way, Sunnyvale, CA 94089',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 4,
        detailUrl: '/homedetails/1110-Pecos-Way-Sunnyvale-CA-94089/19491135_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/783e9e4c00bd452c8073292ed91abfbf-p_e.jpg',
        latitude: 37.397854,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1108,
        longitude: -121.99448,
        lotAreaUnit: 'sqft',
        lotAreaValue: 6066,
        price: 1398000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 3966,
        variableData: {
            text: 'Open: Sun. 1-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 1481400,
        zpid: '19491135',
    },
    {
        address: '1725 Chitamook Ct, Sunnyvale, CA 94087',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 2,
        google_place_id: "ChIJjQmTaV0E9YgRC2MLmS_e_mY",
        detailUrl: '/homedetails/1725-Chitamook-Ct-Sunnyvale-CA-94087/19625117_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/afebde060a5a8b9d4a90ad10b82f2f63-p_e.jpg',
        latitude: 37.336086,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
        },
        livingArea: 1350,
        longitude: -122.05624,
        lotAreaUnit: 'sqft',
        lotAreaValue: 8712,
        price: 2250000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 4700,
        variableData: {
            text: '2 days on Zillow',
            type: 'DAYS_ON',
        },
        zestimate: 2504900,
        zpid: '19625117',
    },
    {
        address: '1407 S Mary Ave, Sunnyvale, CA 94087',
        bathrooms: 2,
        bedrooms: 4,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 1,
        detailUrl: '/homedetails/1407-S-Mary-Ave-Sunnyvale-CA-94087/19622657_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/1127775f7ebbf2f95c458e0d9b20f3ab-p_e.jpg',
        latitude: 37.347683,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1832,
        longitude: -122.05034,
        lotAreaUnit: 'sqft',
        lotAreaValue: 7405,
        price: 2700000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5625,
        variableData: {
            text: 'Open: Sat. 1-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 3002600,
        zpid: '19622657',
    },
    {
        address: '624 W Remington Dr, Sunnyvale, CA 94087',
        bathrooms: 2,
        bedrooms: 4,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 8,
        detailUrl: '/homedetails/624-W-Remington-Dr-Sunnyvale-CA-94087/19540305_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/e585113dd905f12765b20e89759fc07f-p_e.jpg',
        latitude: 37.35877,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1657,
        longitude: -122.038025,
        lotAreaUnit: 'sqft',
        lotAreaValue: 7208,
        price: 2599888,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5269,
        variableData: {
            text: 'Open: Sun. 1:30-4:30pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 2854400,
        zpid: '19540305',
    },
    {
        address: '849 Gladiola Dr, Sunnyvale, CA 94086',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 3,
        detailUrl: '/homedetails/849-Gladiola-Dr-Sunnyvale-CA-94086/19546549_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/df59aa3bb6f25d383d4465d493c89b0b-p_e.jpg',
        latitude: 37.364056,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1533,
        longitude: -122.01626,
        lotAreaUnit: 'sqft',
        lotAreaValue: 6534,
        price: 1998000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 4500,
        variableData: {
            text: 'Open: Sun. 2-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 2205000,
        zpid: '19546549',
    },
    {
        address: '524 E Washington Ave, Sunnyvale, CA 94086',
        bathrooms: 3,
        bedrooms: 4,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 2,
        google_place_id: "ChIJjQmTaV0E9YgRC2MLmS_e_mY",
        detailUrl: '/homedetails/524-E-Washington-Ave-Sunnyvale-CA-94086/19545282_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/840b85b052cec08f8415d643f58952d1-p_e.jpg',
        latitude: 37.374428,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 750,
        longitude: -122.02295,
        lotAreaUnit: 'sqft',
        lotAreaValue: 4356,
        price: 1499000,
        priceChange: -50000,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 3297,
        variableData: {
            text: 'Open: Sun. 1:30-4:30pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 1683500,
        zpid: '19545282',
    },
    {
        address: '388 S Bernardo Ave, Sunnyvale, CA 94086',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 4,
        detailUrl: '/homedetails/388-S-Bernardo-Ave-Sunnyvale-CA-94086/19519608_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/75afa27d6dd6dca2fabb24716fda7c41-p_e.jpg',
        latitude: 37.377743,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1628,
        longitude: -122.05472,
        lotAreaUnit: 'sqft',
        lotAreaValue: 6970,
        price: 1898000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5299,
        variableData: {
            text: 'Open: Sun. 1:30-4:30pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 2113200,
        zpid: '19519608',
    },
    {
        address: '801 Trenton Dr, Sunnyvale, CA 94087',
        bathrooms: 2,
        bedrooms: 4,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 3,
        detailUrl: '/homedetails/801-Trenton-Dr-Sunnyvale-CA-94087/19540817_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/8a7f7123d3a99b5e46a46b75598a100e-p_e.jpg',
        latitude: 37.35452,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1727,
        longitude: -122.04618,
        lotAreaUnit: 'sqft',
        lotAreaValue: 7841,
        price: 2698000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5299,
        variableData: {
            text: 'Open: Sun. 1:30-4:30pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 3075100,
        zpid: '19540817',
    },
    {
        address: '478 Peralta Ave, Sunnyvale, CA 94086',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 1,
        detailUrl: '/homedetails/478-Peralta-Ave-Sunnyvale-CA-94086/19520304_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/f569c62b051973b918351b9a64b8cc5e-p_e.jpg',
        latitude: 37.372356,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1662,
        longitude: -122.04261,
        lotAreaUnit: 'sqft',
        lotAreaValue: 5227,
        price: 2150000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5200,
        variableData: {
            text: 'Open: Sun. 1-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 2347500,
        zpid: '19520304',
    },
    {
        address: '1700 Peacock Ave, Sunnyvale, CA 94087',
        bathrooms: 5,
        bedrooms: 4,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 36,
        detailUrl: '/homedetails/1700-Peacock-Ave-Sunnyvale-CA-94087/2053154539_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/678ba2c05a278a467bab46fc763f09a7-p_e.jpg',
        latitude: 37.342762,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
        },
        livingArea: 2778,
        longitude: -122.007355,
        lotAreaUnit: 'sqft',
        lotAreaValue: 7091,
        price: 3989000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 8210,
        variableData: {
            text: '36 days on Zillow',
            type: 'DAYS_ON',
        },
        zestimate: 3953800,
        zpid: '2053154539',
    },
    {
        address: '615 Morse Ave, Sunnyvale, CA 94085',
        bathrooms: 2,
        bedrooms: 4,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 4,
        detailUrl: '/homedetails/615-Morse-Ave-Sunnyvale-CA-94085/19542471_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/79e3dc648a02f3de22cd60a32adcc8ea-p_e.jpg',
        latitude: 37.39026,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1866,
        longitude: -122.020065,
        lotAreaUnit: 'sqft',
        lotAreaValue: 6098.4,
        price: 1788000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5200,
        variableData: {
            text: 'Open: Sun. 1am-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 2087700,
        zpid: '19542471',
    },
    {
        address: '873 Cedar Ave, Sunnyvale, CA 94086',
        bathrooms: 1,
        bedrooms: 2,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 16,
        detailUrl: '/homedetails/873-Cedar-Ave-Sunnyvale-CA-94086/19545038_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/65436a6881ceda682a9f4f8b4b10ce7d-p_e.jpg',
        latitude: 37.377377,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 798,
        longitude: -122.01751,
        lotAreaUnit: 'sqft',
        lotAreaValue: 5227.2,
        price: 999000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 3249,
        variableData: {
            text: 'Open: Sun. 1-5pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: null,
        zpid: '19545038',
    },
    {
        address: '854 W Iowa Ave, Sunnyvale, CA 94086',
        bathrooms: 1,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 3,
        detailUrl: '/homedetails/854-W-Iowa-Ave-Sunnyvale-CA-94086/19520225_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/3e7ed417aca5e9131e012754a7ba0eed-p_e.jpg',
        latitude: 37.374306,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 924,
        longitude: -122.041824,
        lotAreaUnit: 'sqft',
        lotAreaValue: 6098,
        price: 1998000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 4019,
        variableData: {
            text: 'Open: Sun. 1:30-4:30pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 2272800,
        zpid: '19520225',
    },
    {
        address: '1510 S Bernardo Ave, Sunnyvale, CA 94087',
        bathrooms: 3,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 3,
        detailUrl: '/homedetails/1510-S-Bernardo-Ave-Sunnyvale-CA-94087/19622147_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/5699087aeae494d210bed838a479f50d-p_e.jpg',
        latitude: 37.344227,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1816,
        longitude: -122.05928,
        lotAreaUnit: 'sqft',
        lotAreaValue: 7841,
        price: 2900000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5710,
        variableData: {
            text: 'Open: Sun. 1:30-4:30pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 3312100,
        zpid: '19622147',
    },
    {
        address: '652 Harcot Ter, Sunnyvale, CA 94085',
        bathrooms: 3,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 3,
        detailUrl: '/homedetails/652-Harcot-Ter-Sunnyvale-CA-94085/2071016675_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/d3c4f7515d510415c2b56b9e73952152-p_e.jpg',
        latitude: 37.39288,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1679,
        longitude: -122.02702,
        lotAreaUnit: 'sqft',
        lotAreaValue: 2614,
        price: 2198000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 2700,
        variableData: {
            text: 'Open: Sun. 1-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: null,
        zpid: '2071016675',
    },
    {
        address: '591 Fort Laramie Dr, Sunnyvale, CA 94087',
        bathrooms: 2,
        bedrooms: 4,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 11,
        detailUrl: '/homedetails/591-Fort-Laramie-Dr-Sunnyvale-CA-94087/19624384_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/37634935866a33778ab1d97a477a29f8-p_e.jpg',
        latitude: 37.343956,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
        },
        livingArea: 2107,
        longitude: -122.03725,
        lotAreaUnit: 'sqft',
        lotAreaValue: 8276,
        price: 2999888,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 6499,
        variableData: {
            text: '11 days on Zillow',
            type: 'DAYS_ON',
        },
        zestimate: 3272500,
        zpid: '19624384',
    },
    {
        address: '140 Carson Ct, Sunnyvale, CA 94086',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 4,
        detailUrl: '/homedetails/140-Carson-Ct-Sunnyvale-CA-94086/19520017_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/a5f8c7d6321db868fac775737efb5b54-p_e.jpg',
        latitude: 37.380657,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
            is_openHouse: true,
        },
        livingArea: 1673,
        longitude: -122.04627,
        lotAreaUnit: 'sqft',
        lotAreaValue: 5227,
        price: 2088888,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 5200,
        variableData: {
            text: 'Open: Sun. 1-4pm',
            type: 'OPEN_HOUSE',
        },
        zestimate: 2329600,
        zpid: '19520017',
    },
    {
        address: '1116 Spinosa Dr, Sunnyvale, CA 94087',
        bathrooms: 2,
        bedrooms: 3,
        contingentListingType: null,
        country: 'USA',
        currency: 'USD',
        dateSold: null,
        daysOnZillow: 52,
        detailUrl: '/homedetails/1116-Spinosa-Dr-Sunnyvale-CA-94087/19539974_zpid/',
        hasImage: true,
        imgSrc: 'https://photos.zillowstatic.com/fp/348719db62c73166d02126512c88c76c-p_e.jpg',
        latitude: 37.357933,
        listingStatus: 'FOR_SALE',
        listingSubType: {
            is_FSBA: true,
        },
        livingArea: 1220,
        longitude: -122.03667,
        lotAreaUnit: 'sqft',
        lotAreaValue: 7200,
        price: 2288000,
        priceChange: null,
        propertyType: 'SINGLE_FAMILY',
        rentZestimate: 4649,
        variableData: {
            text: '52 days on Zillow',
            type: 'DAYS_ON',
        },
        zestimate: 2262300,
        zpid: '19539974',
    },
];

/**
 * This function is used for the adding a delay in the developmode while returning the response
 * @param {*} ms 
 * @returns 
 */
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function postProcessHouseInfo(houseDetail, idx) {
    houseDetail.key = idx;
    houseDetail.street_address = houseDetail.address;
    return houseDetail;
}

export const getHouseList = async function (address, google_place_id) {
    const apiUrl = `/v1/api/data/houselistings/${address}?google_place_id=${google_place_id}`;

    const state = store.getState();
    const userData = state.user; 

    const email = userData.userData?.user_details?.email??null;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'email': email
            }
        });
        // Check if the response status is OK (200)
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const houseList = await response.json();
        return houseList.map((obj) => postProcessHouseInfo(obj));
    } catch (error) {
        log.error(error);
        if (process.env.NODE_ENV === 'development') {
            await delay(1000);
            return sample_addresses.map(postProcessHouseInfo)
        }
        return [];
    }
};

export const getHouseDetails = async function (address, google_place_id) {
    const apiUrl = `/v1/api/data/house/${address}?google_place_id=${google_place_id}`;
    
    const state = store.getState();
    const userData = state.user; 

    const email = userData.userData?.user_details?.email??null;

    try {
        console.log(apiUrl, "try");
        const response = await fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'email': email
            }
        });
        // Check if the response status is OK (200)
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.log(apiUrl, "catch");
        // Handle any errors that occur during the fetch operation
        log.error('Fetch error:', error);
        if (process.env.NODE_ENV === 'development') {
            await delay(1000);
            return {
                house_price: 1695000,
                down_payment_percent: 20,
                interest_rate: 5.5,
                intial_rent: 3000,
                hold_for_years: 30,
                HOA: 560,
            };
        } else {
            return {}
        }
    }
};

export const google_house_details = async function (address) {
    const apiUrl = `/v1/api/data/google_house_details/${address}`;

    const state = store.getState();
    const userData = state.user; 

    const email = userData.userData?.user_details?.email??null;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'email': email
            }
        });
        // Check if the response status is OK (200)
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        // Handle any errors that occur during the fetch operation
        log.error('Fetch error:', error);
        await delay(1000);
        return {};
    }
};

const dataService = {
    getHouseDetails,
    getHouseList,
    google_house_details,
};

export default dataService;
