import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styled container for centering content
const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100px',
});

// Styled text with animation
const LoadingText = styled(Typography)({
  marginTop: '16px',
  fontSize: '18px',
  color: '#1976d2', // You can customize the color here
  '&::after': {
    content: '"..."'.repeat(3),
    display: 'inline-block',
    animation: 'blink 1.5s infinite step-start',
  },
  '@keyframes blink': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
});

const ByvrLoadingAIResponse = () => {
  return (
    <Container>
      <CircularProgress color="primary" />
      <LoadingText>Loading AI generated result</LoadingText>
    </Container>
  );
};

export default ByvrLoadingAIResponse;
