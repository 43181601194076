import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    houseDetail: {},
};

const houseSlice = createSlice({
    name: 'house',
    initialState,
    reducers: {
        housedetails: (state, action) => {
            state.houseDetail = action.payload;
        },
    },
});

export const { housedetails } = houseSlice.actions;

export default houseSlice.reducer;
