
import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import Chart from 'chart.js/auto';

function FinancialSetting() {

    useEffect(() => {

    }, []);

    return (
        <Box className="pt-4" sx={{ p: 2 }} style={{ float: 'left', width: '100%' }}>
            <img src="img/thm.png" style={{ width: 100, height: 100 }} alt="logo" />
            <Box className="price-input-container">
                <Box className="price-input" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box sx={{ width: '50%', p: 1 }}>
                        <div className="price-field">
                            <span>Appreciation</span>
                            <input type="number" className="min-input" value="2500" style={{ width: '150px' }} />
                        </div>
                        <div className="price-field">
                            <span>Appreciation</span>
                            <input type="number" className="min-input" value="2500" style={{ width: '150px' }} />
                        </div>
                    </Box>
                    <Box sx={{ width: '50%', p: 1 }}>
                        <div className="price-field">
                            <span>Appreciation</span>
                            <input type="number" className="min-input" value="2500" style={{ width: '150px' }} />
                        </div>
                        <div className="price-field">
                            <span>Appreciation</span>
                            <input type="number" className="min-input" value="2500" style={{ width: '150px' }} />
                        </div>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default FinancialSetting;
