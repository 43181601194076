/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import logger from '../logger';

export const generateChartData = function (houseDetail, hold_for_years) {
    // constant for the particular house
    const house_price_appreciation = 4;
    const invest_profit_rate = 8;
    const rent_appreciation = 2.5;

    let loan_amount = ((100 - houseDetail.down_payment_percent) * houseDetail.house_price) / 100;
    const closing_cost = (2.5 * loan_amount) / 100;
    const inital_invest_equity = ((houseDetail.down_payment_percent * houseDetail.house_price) / 100) + closing_cost;
    const other_monthly_home_owner_expense = 500 + houseDetail.HOA; // insurance + house mentainance

    logger.debug(`loan_amount - ${loan_amount}`);
    logger.debug(`closing_cost - ${closing_cost}`);
    logger.debug(`inital_invest_equity - ${inital_invest_equity}`);
    logger.debug(`other_monthly_home_owner_expense - ${other_monthly_home_owner_expense}`);

    const loan_term = 12 * hold_for_years;
    let current_house_price = houseDetail.house_price;
    let current_invest_equity = inital_invest_equity;
    let current_rent = houseDetail.intial_rent;
    let current_expense = inital_invest_equity;
    let current_house_equity = inital_invest_equity;

    const R = 1 + (houseDetail.interest_rate) / (12 * 100);
    const mortgate = (loan_amount * R ** loan_term * (1 - R)) / (1 - R ** loan_term);

    const R2 = 1 + (house_price_appreciation) / (12 * 100);
    const R3 = 1 + (invest_profit_rate) / (12 * 100);
    const R4 = 1 + (rent_appreciation) / (12 * 100);

    const years = [];
    const monthly_Interest = [];
    const loan_Balance = [];
    const house_value = [];
    const house_equity = [];
    const house_rent = [];
    const investment_equity = [];
    const total_expense = [];
    const home_profits = [];

    const curYear = new Date().getFullYear();

    for (let i = 0; i < loan_term + 1; i++) {
        const loan_interest = loan_amount * (R - 1);
        loan_amount -= (mortgate - loan_interest);
        if (i % 12 === 0) years.push((curYear + i / 12).toString());
        if (i % 12 === 0) monthly_Interest.push(loan_interest);
        if (i % 12 === 0) loan_Balance.push(loan_amount);

        current_house_price *= R2;
        if (i % 12 === 0) house_value.push(current_house_price);

        // your house equity is current house value  minus the loan amount pending
        // instead of all the money you need to pay
        current_house_equity = current_house_price - loan_amount;
        if (i % 12 === 0) house_equity.push(current_house_equity);

        current_rent *= R4;
        if (i % 12 === 0) house_rent.push(current_rent);

        // current investment equity is equity from previous investment
        // plus the new investment done from mortgage - rent
        // - if rent is more then your investment equity goes
        // down assuming you took our money from investment
        current_invest_equity = current_invest_equity * R3 + (mortgate + other_monthly_home_owner_expense - current_rent);
        if (i % 12 === 0) investment_equity.push(current_invest_equity);

        const current_profit = current_house_equity - current_invest_equity;
        if (i % 12 === 0) home_profits.push(current_profit);

        current_expense += mortgate;
        if (i % 12 === 0) total_expense.push(current_expense);
    }
    logger.debug(' Mortgaze amount - ', mortgate);
    logger.debug(' total_expense - ', current_expense);
    logger.debug(' investment_equity - ', current_invest_equity);
    logger.debug(' house_equity - ', current_house_equity);
    logger.debug(' final_rent - ', current_rent);

    return {
        years,
        monthly_Interest,
        loan_Balance,
        house_value,
        house_equity,
        house_rent,
        investment_equity,
        total_expense,
        home_profits,
    };
};

/**
 *
 * @returns
 */
export const getChartData = function () {
    const houseDetail = {
        house_price: 1695000,
        down_payment_percent: 20,
        interest_rate: 5.5,
        intial_rent: 3000,
        hold_for_years: 30,
        HOA: 560,
    };

    const data = generateChartData(houseDetail);
    logger.debug(data);
    return Promise.resolve(data);
};

export default {
    getChartData,
    generateChartData,
};
