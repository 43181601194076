// CustomLegend.js
import React from 'react';

function CustomLegend({ data }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {data.datasets.map((dataset, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <div style={{
                        width: '20px', height: '20px', backgroundColor: dataset.borderColor, marginRight: '8px',
                    }}
                    />
                    <span>{dataset.label}</span>
                </div>
            ))}
        </div>
    );
}

export default CustomLegend;
