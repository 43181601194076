import { createTheme, ThemeProvider } from '@mui/material/styles';

export const defaultTheme = createTheme({
    spacing: 8,  // Default Material UI spacing
    typography: {
      fontSize: 14,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '6px 16px',
          },
        },
      },
    },
  });

  
  export const compactTheme = createTheme({
  spacing: 4,  // Reduces the default spacing unit from 8px to 4px

  typography: {
    fontSize: 14,  // Smaller base font size
    h1: { fontSize: '1.5rem', lineHeight: 1.2 },
    h2: { fontSize: '1.25rem', lineHeight: 1.3 },
    h3: { fontSize: '1.1rem', lineHeight: 1.3 },
    body1: { fontSize: '0.875rem', lineHeight: 1.4 },
    body2: { fontSize: '0.75rem', lineHeight: 1.35 },
    button: { fontSize: '0.75rem', textTransform: 'none' },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '4px 8px',  // Reduces button padding
          minWidth: 28,  // Reduces button minimum width
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '4px',  // Reduces default TextField margin
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '4px',  // Smaller padding inside cards
          margin: '4px',  // Smaller margin around cards
        },
      },
    },
    MuiTab: {
        styleOverrides: {
          root: {
            padding: '0px 8px',  // Reduce padding for compact appearance
            minWidth: 72,  // Reduce minimum width if needed
          },
        },
      },
    MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: 32,  // Decreases the minimum height of the accordion header
            padding: '0 8px',  // Reduces padding around the accordion header
            '&.Mui-expanded': {
              minHeight: 32,  // Keeps the height consistent when expanded
            },
          },
          content: {
            margin: '6px 0',  // Adjusts spacing around the content
            '&.Mui-expanded': {
              margin: '6px 0',  // Keeps content spacing consistent when expanded
            },
            fontSize: '0.875rem',  // Reduces the font size of the accordion header text
          },
        },
      },
  },
});


export default compactTheme;
