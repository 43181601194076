import { Box, Container, Grid, List } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <Box id='contact-section' className="footer">
            <Container className="footer-inner">
                <Grid container>
                    <Grid item xl={4} lg={4} md={6} sm={6}>
                        <Box className="footer-item clearfix">
                            <img src="img/logos/logo.png" alt="logo" className="f-logo" />
                            <Box className="texts">
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.
                                    Nulla finibus lobortis pulvinar. Donec a consectetur nulla. Nulla posuere sapien vitae
                                    Nulla posuere sapien vitae. Nulla finibus lobortis pulvinar. Donec a consectetur nulla.
                                    Nulla posuere.</p> */}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                        <Box className="footer-item">
                            <h4>Contact Us</h4>
                            <ul className="contact-info">
                                {/* <li>
                                    <i className="flaticon-pin"></i>20/F Green Road, Dhanmondi, USA
                                </li> */}
                                <li>
                                    <i className="flaticon-mail-1"></i><Link to=".">info@byvr.ai</Link>
                                </li>
                                {/* <li>
                                    <i className="flaticon-earth"></i><Link to=".">byvr.ai@byvr.com</Link>
                                </li>
                                <li>
                                    <i className="flaticon-phone"></i><a href="tel:+55-447-034-7071">+0477 85X6 312</a>
                                </li>
                                <li>
                                    <i className="flaticon-fax"></i>+0044 85X6 000
                                </li> */}
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item xl={2} lg={2} md={6} sm={6}>
                        <Box className="footer-item">
                            {/* <h4>Useful Links</h4> */}
                            {/* <ul className="links">
                                <li>
                                    <Link to=".">All Listing</Link>
                                </li>
                                <li>
                                    <Link to=".">Fair Housing Guide</Link>
                                </li>
                                <li>
                                    <Link to=".">About Us</Link>
                                </li>
                                <li>
                                    <Link to=".">Pricing Tables</Link>
                                </li>
                                <li>
                                    <Link to=".">Advertise</Link>
                                </li>
                                <li>
                                    <Link to=".">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to=".">Privacy Portal / Terms of use</Link>
                                </li>
                            </ul> */}
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                        {/* <Box className="footer-item clearfix">
                            <h4>Subscribe</h4>
                            <Box className="Subscribe-box">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                    has been the industry&apos;s standard dummy </p>
                                <form className="form-inline" action="#" method="GET">
                                    <input type="text" className="form-control mb-sm-0" id="inlineFormInputName3"
                                        placeholder="Email Address" />
                                    <button type="submit" className="btn"><i className="fa fa-paper-plane"></i></button>
                                </form>
                            </Box>
                        </Box> */}
                    </Grid>
                </Grid>
            </Container>
            <Box className="sub-footer">
                <Container>
                    <Grid container>
                        <Grid item lg={8} md={12}>
                            <p className="copy">© 2024 <Link to=".">byvr</Link> Designed by Kytics.</p>
                        </Grid>
                        <Grid item lg={4} md={12}>
                            <List className="social-list clearfix">
                                <li><Link to="." className="facebook-bg"><i className="fa fa-facebook"></i></Link></li>
                                <li><Link to="." className="twitter-bg"><i className="fa fa-twitter"></i></Link></li>
                                <li><Link to="." className="google-bg"><i className="fa fa-google-plus"></i></Link></li>
                                <li><Link to="." className="rss-bg"><i className="fa fa-rss"></i></Link></li>
                                <li><Link to="." className="linkedin-bg"><i className="fa fa-linkedin"></i></Link></li>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}
