// import logo from './BackGround.png';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import MenuAppBar from './Components/ByvrMenuAppBar';
import authServices from './Services/authService';
import ByvrHome from './Pages/ByvrHome';
import ByvrDashboard from './Pages/ByvrDashboard';
import ByvrLoginCard from './Pages/ByvrLoginPage';
import ByvrDashboardBulk from './Pages/ByvrDashboardBulk';
import logger from './utils/logger';
import { login } from './features/auth/authSlice';
import store from './store';
import { useSelector } from 'react-redux';
import { setUserData, clearUserData } from './features/user/userSlice';
import { compactTheme } from './theme/byvrDefaultTheme';
import { CustomThemeProvider, useThemeContext } from './theme/byvrThemeContext';
import CssBaseline from '@mui/material/CssBaseline';

function AppContent() {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            // If callback has code parameter, exchange it for token
            authServices.exchangeCodeForToken(code)
                .then((resp) => {
                    logger.debug(resp);
                    authServices.storeUserInfoInSession(resp);
                    authServices.createUser(resp);
                    dispatch(setUserData(resp));
                    dispatch(login());
                });
        } else {
            authServices.getUserInfoInSession()
                .then((resp) => {
                    logger.debug(resp);
                    if (resp.token) {
                        dispatch(setUserData(resp));
                        dispatch(login());
                    }
                })
                .catch((err) => {
                    logger.error(err);
                    // dispatch(login());
                });
        }
    }, [dispatch]);

    const handleClearUserData = () => {
        dispatch(clearUserData());
    };

    return (
        <Router>
            <MenuAppBar userData={userData} clearUserData={handleClearUserData} />
            <Routes>
                <Route exact path="/" element={<ByvrHome userData={userData} />} />
                <Route path="/login" element={<ByvrLoginCard />} />
                <Route path="/analytics" element={<ByvrDashboard />} />
                <Route path="/bulk-analytics" element={<ByvrDashboardBulk />} />
                {/* <Route path="/contact" component={Contact} /> */}
                {/* Add more routes as needed */}
            </Routes>
        </Router>
    );
}

function App() {
    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <CssBaseline />
                <AppContent />
            </CustomThemeProvider>

        </Provider>
    );
}

export default App;
