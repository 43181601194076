/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';

import {
    Chart,
    LineController,
    LineElement,
    Filler,
    PointElement,
    LinearScale,
    TimeScale,
    Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { getHovervalue } from '../utils/Utils';

Chart.register(
    LineController,
    LineElement,
    Filler,
    PointElement,
    LinearScale,
    TimeScale,
    Tooltip,
);

const verticalLinePlugin = {
    id: 'verticalLine',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            const activePoint = chart.tooltip._active[0];
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.stroke();
            ctx.restore();
        }
    },
};

Chart.register(verticalLinePlugin);

function ProfitLineChart({ data, width, height, singleAddress, mobileView }) {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');

        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: data.labels, 
                datasets: data.datasets.map((dataset, index) => ({
                    ...dataset,
                    backgroundColor: index === 0
                        ? 'rgba(255, 99, 132, 0.2)'
                        : 'rgba(54, 162, 235, 0.2)',
                    borderColor: index === 0
                        ? 'rgba(255, 99, 132, 1)'
                        : 'rgba(54, 162, 235, 1)',
                    fill: false,
                    tension: 0.4, // Make the line curved
                    pointRadius: 2, // Size of the dots
                    pointBackgroundColor: index === 0
                        ? 'rgba(255, 99, 132, 1)'
                        : 'rgba(54, 162, 235, 1)',
                })),
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: 5,
                },
                plugins: {
                    title: {
                        display: true,
                        text: `Value Growth`,
                    },
                    tooltip: {
                        enabled: true,
                        mode: 'index', 
                        intersect: false, 
                        callbacks: {
                            title: (context) => '', // Disable tooltip title
                            label: (context) => {
                                const value = context.raw; 
                                return `$${parseInt(value / 1000)} K`;
                            },
                        },
                        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker tooltip background
                        titleColor: '#ffffff', // White title color
                        bodyColor: '#ffffff', // White body color
                    },
                    legend: {
                        display: true,
                        labels: {
                            color: '#4a4a4a', // Dark gray legend labels
                        },
                    },
                },
                scales: {
                    x: {
                        type: 'linear',
                        display: true,
                        title: {
                            display: true,
                            text: 'Date',
                        },
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)', // Light gray grid lines
                            borderDash: [5, 5], // Dashed grid lines
                        },
                        ticks: {
                            color: '#4a4a4a',
                            callback: function (value) {
                                return value.toString(); // Display values
                            },
                        },
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        title: {
                            display: true,
                            text: 'Equity',
                        },
                        min: 0, 
                        ticks: {
                            color: '#4a4a4a', // Dark gray ticks
                            callback: function (value) {
                                if (value === 0) return '$0.00 M';
                                return '$' + (value / 1000000).toFixed(2) + ' M';
                            },
                        },
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)', // Light gray grid lines
                            borderDash: [5, 5], // Dashed grid lines
                        },
                    },
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
            },
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data]);

    return (
        <div style={{ height: singleAddress || mobileView ? '60vh' : '' }}>
            <canvas ref={canvasRef} width={width} height={height} />
        </div>
    );
}

export default ProfitLineChart;
