import React, { useRef, useEffect } from 'react';
import {
    Chart,
    LineController,
    LineElement,
    Filler,
    PointElement,
    LinearScale,
    TimeScale,
    Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

Chart.register(
    LineController,
    LineElement,
    Filler,
    PointElement,
    LinearScale,
    TimeScale,
    Tooltip,
);

function ExpenseLineChart({ data, width, height, singleAddress, mobileView }) {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (!data || !data.datasets || !Array.isArray(data.datasets) || data.datasets.length === 0) {
            console.error('Invalid or empty data structure');
            return; 
        }

        const ctx = canvasRef.current.getContext('2d');

        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: data.labels, 
                datasets: data.datasets.map((dataset, index) => ({
                    ...dataset,
                    backgroundColor: index === 0 ? 'rgba(255, 99, 132, 0.2)' : 'rgba(54, 162, 235, 0.2)',
                    borderColor: index === 0 ? 'rgba(255, 99, 132, 1)' : 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    fill: false,
                    tension: 0.4,
                    pointRadius: 2,
                    pointBackgroundColor: index === 0 ? 'rgba(255, 99, 132, 1)' : 'rgba(54, 162, 235, 1)',
                })),
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: 20,
                },
                plugins: {
                    title: {
                        display: true,
                        text: `Expense Over Time`,
                    },
                    tooltip: {
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: () => '',
                            label: (context) => {
                                const value = context.raw;
                                return `$${parseInt(value)}`;
                            },
                        },
                        backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                        titleColor: '#ffffff', 
                        bodyColor: '#ffffff',
                    },
                    legend: {
                        display: true,
                        labels: {
                            color: '#4a4a4a',
                        },
                    },
                },
                scales: {
                    x: {
                        type: 'linear',
                        display: true,
                        title: {
                            display: true,
                            text: 'Date',
                        },
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)', 
                            borderDash: [5, 5], 
                        },
                        min: Math.min(...data.labels),
                        ticks: {
                            color: '#4a4a4a',
                            callback: function (value) {
                                return value.toString();
                            },
                        },
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        title: {
                            display: true,
                            text: 'Expense',
                        },
                        min: 0,
                        ticks: {
                            color: '#4a4a4a',
                            callback: function (value) {
                                if (value === 0) return '$0.00 K';
                                return '$' + (value / 1000).toFixed(2) + ' K';
                            },
                        },
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)',
                            borderDash: [5, 5],
                        },
                    },
                },
                interaction: {
                    intersect: false,
                    mode: 'index', 
                },
            },
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data]);

    return (
        <div style={{ height: singleAddress || mobileView ? '60vh' : '' }}>
            <canvas ref={canvasRef} width={width} height={height} />
        </div>
    );
}

export default ExpenseLineChart;
