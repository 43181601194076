import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { housedetails } from '../../features/house/houseSlice';

function UserStatusSetting() {
    const dispatch = useDispatch();
    const houseDetail = useSelector((state) => state.house.houseDetail); 
    const [formData, setFormData] = useState({
        buyerStatus: houseDetail.buyerStatus || 1, 
    });

    useEffect(() => {
        if (houseDetail) {
            setFormData((prev) => ({ ...prev, buyerStatus: houseDetail.buyerStatus || 1 }));
        }
    }, [houseDetail]);

    const handleFieldChange = (value) => {
        setFormData((prev) => ({ ...prev, buyerStatus: value })); 
        const updatedHouseData = { ...houseDetail, buyerStatus: value }; 
        dispatch(housedetails(updatedHouseData)); 
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="buyer-status-label">Buyer Status</InputLabel>
                        <Select
                        labelId="buyer-status-label"
                        id="buyer-status"
                        value={formData.buyerStatus} 
                        onChange={(e) => handleFieldChange(e.target.value)} 
                        label="Buyer Status"
                    >
                        <MenuItem value={1}>First-Time Buyer</MenuItem>
                        <MenuItem value={2}>Home Owner</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
        </Grid>
    );
}

export default UserStatusSetting;
