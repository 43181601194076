import Slider from 'react-slick';
import './ByvrLatestNews.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LatestNews = () => {
    const settings = {
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
        arrows: false, // Hide next and prev buttons
    };

    const renderSlides = () => {
        return [...Array(3)].map((_, index) => (
            <div key={index} className="slick-slide-item wow">
                <div className="posts__item">
                    <div className="posts__image">
                        <img src={index === 0 ? 'img/news.jpg' : `img/news${index % 3}.jpg`} alt="banner" />
                    </div>
                    <div className="posts__information">
                        <div className="posts__title">
                            {
                                index === 0 ? <a href="https://www.youtube.com/watch?si=XMDxPUEG0T90JvTJ&v=ETROzuOFffA&feature=youtu.be" target="_blank">Renting vs Buying a Home: The Lie You’ve Been Told
                                </a> : index === 1 ? <a href="https://www.nytimes.com/2023/12/01/podcasts/the-daily/should-you-rent-or-buy-the-new-math.html" target="_blank">Should You Rent or Buy? The New Math.</a> : <a href="https://www.forbes.com/sites/reginacole/2019/07/14/americans-believe-that-renters-are-stigmatized-a-new-study-finds/" target="_blank">Americans Believe That Renters Are Stigmatized, A New Study Finds</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className="testimonial overview-bgi">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-title">
                            <h1>News Buzz</h1>
                        </div>
                    </div>
                </div>
                <div className="slick-slider-area">
                    <Slider {...settings} className="slick-carousel wow fadeInUp delay-04s">
                        {renderSlides()}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default LatestNews;
