import React, { useState, useEffect, useRef } from 'react';
import { Card } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './ByvrHouseMapView.css'; // Ensure this file is imported correctly

// Define a custom icon for the markers
const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
});

function ByvrHouseMapView({ addresses, onAddressClick, mobileView }) {
    const defaultCenter = addresses[0]
        ? [addresses[0].latitude, addresses[0].longitude]
        : [37.7749, -122.4194];
    const defaultZoom = mobileView ? 10 : 12;

    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [mapZoom, setMapZoom] = useState(defaultZoom);
    const mapRef = useRef(null);

    const handleMarkerClick = (address) => {
        setMapCenter([address.latitude, address.longitude]);
        setMapZoom(defaultZoom);
        onAddressClick(address);
    };

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setView(mapCenter, mapZoom);
        }
    }, [mapCenter, mapZoom]);

    // Ensure the map invalidates size when the component mounts
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize();
        }
    }, []);

    // Ensure the map invalidates size on window resize
    useEffect(() => {
        const handleResize = () => {
            if (mapRef.current) {
                mapRef.current.invalidateSize();
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Card className="map-card">
            <MapContainer
                center={mapCenter}
                zoom={mapZoom}
                className="map-container"
                whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {addresses.map((address) => (
                    <Marker
                        key={address.id}
                        position={[address.latitude, address.longitude]}
                        icon={customIcon}
                        eventHandlers={{
                            click: () => handleMarkerClick(address),
                        }}
                    >
                        <Popup>{address.address}</Popup>
                    </Marker>
                ))}
            </MapContainer>
        </Card>
    );
}

export default ByvrHouseMapView;
